import React from "react";

const Contactus = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Contact Us</h1>
        
        <p className="text-gray-700 mb-6">
          If you have any questions or concerns about our Privacy Policy, please contact us using the details below:
        </p>

        <div className="space-y-4">
          <div className="flex items-center">
            <span className="font-semibold text-gray-900 w-32">Company:</span>
            <span className="text-gray-700">Aarang Jewels</span>
          </div>

          <div className="flex items-center">
            <span className="font-semibold text-gray-900 w-32">Email:</span>
            <a href="mailto:Aarangjewels@gmail.com" className="text-indigo-600 hover:underline">
              Aarangjewels@gmail.com
            </a>
          </div>

          <div className="flex items-start">
            <span className="font-semibold text-gray-900 w-32">Address:</span>
            <span className="text-gray-700">
              No.8, Vivekananda road, SBI staff housing colony, 3rd stage, 4th block, 8th main, Basaveshwara nagar, Bangalore - 560079
            </span>
          </div>
        </div>

        {/* <section className="mt-8">
          <h2 className="text-2xl font-semibold text-gray-900 mb-4">Feel Free to Reach Out</h2>
          <form className="space-y-4">
            <div>
              <label htmlFor="name" className="block text-gray-700 font-medium mb-2">
                Name
              </label>
              <input
                type="text"
                id="name"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                placeholder="Your full name"
                required
              />
            </div>

            <div>
              <label htmlFor="email" className="block text-gray-700 font-medium mb-2">
                Email
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                placeholder="Your email address"
                required
              />
            </div>

            <div>
              <label htmlFor="message" className="block text-gray-700 font-medium mb-2">
                Message
              </label>
              <textarea
                id="message"
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
                rows="4"
                placeholder="Your message"
                required
              ></textarea>
            </div>

            <button
              type="submit"
              className="w-full bg-indigo-600 text-white py-2 rounded-md hover:bg-indigo-700 focus:outline-none"
            >
              Send Message
            </button>
          </form>
        </section> */}
      </div>
    </div>
  );
};

export default Contactus;
