import React, { createContext, useContext, useState, useEffect } from 'react';

const CartContext = createContext();

export const useCart = () => {
  return useContext(CartContext);
};

export const CartProvider = ({ children }) => {
  const [cartCount, setCartCount] = useState(0);
  const [cart, setCart] = useState([]);
  useEffect(() => {
    const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
    setCart(storedCart);
  }, []);

  const addToCart = (item) => {
    debugger;
    if(isInCart(item,cart)){
                alert('already added')
            }else{
              if(!item.qty){
                item.qty=1;
              }
    const newCart = [...cart, item];
    setCart(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart));
    alert('added to cart')
            }
  };
  // Check if a product is already in the cart
  const isInCart = (productId,storedCart) => {
    return storedCart.some((item) => item.id === productId.id);
  };
  const removeFromCart = (itemId) => {
    const newCart = cart.filter(item => item.id !== itemId.id);
    setCart(newCart);
    localStorage.setItem('cart', JSON.stringify(newCart));
  };
//   useEffect(() => {
//     const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
//     setCartCount(storedCart.length);
//   }, []);

//   const addToCart = (item) => {
//     const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
//     if(isInCart(item,storedCart)){
//         alert('already added')
//     }else{
        
//         storedCart.push(item);
//         localStorage.setItem('cart', JSON.stringify(storedCart));
//         setCartCount(storedCart.length); // Update state to reflect the new count
//     }
   
//   };
const clearCart = () => {
    setCart([]);
    localStorage.setItem('cart', JSON.stringify([]));
  };
  return (
    <CartContext.Provider value={{ cart, addToCart, removeFromCart,clearCart  }}>
      {children}
    </CartContext.Provider>
  );
};
