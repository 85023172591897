import React from "react";

const AccessibilityPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Accessibility Policy</h1>

        <p className="text-gray-700 mb-6">
          Aarang Jewels is committed to ensuring that our website and services are accessible to everyone, including individuals with disabilities. We strive to create an inclusive experience for all customers and continuously work to enhance accessibility on our website.
        </p>

        <section className="space-y-8">
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">1. Accessible Features</h2>
            <p className="text-gray-700 mb-4">
              To make our website easier to use, we have implemented the following features:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li><strong>Keyboard Navigation:</strong> Our website is navigable using only a keyboard, allowing users to move through content without a mouse.</li>
              <li><strong>Text Alternatives:</strong> Images, buttons, and other non-text content have alternative text descriptions for screen readers.</li>
              <li><strong>High Contrast Options:</strong> We use readable fonts and color contrast to enhance text visibility and readability.</li>
              <li><strong>Accessible Forms:</strong> Our forms include labels, instructions, and error messages that are accessible to screen readers.</li>
              <li><strong>Responsive Design:</strong> Our website is optimized for a variety of devices, including mobile and tablet screens, for an accessible experience across devices.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">2. Ongoing Efforts and Training</h2>
            <p className="text-gray-700">
              We are dedicated to improving the accessibility of our website, and our team regularly reviews and updates our site to enhance the user experience for all customers. We stay updated on best practices in web accessibility and may conduct audits to identify and resolve accessibility issues.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">3. Feedback</h2>
            <p className="text-gray-700 mb-4">
              We welcome your feedback to help us improve our accessibility efforts. If you experience any issues while accessing our website or have suggestions on how we can improve, please contact us:
            </p>
            <div className="space-y-2">
              <p className="text-gray-700"><strong>Aarang Jewels</strong></p>
              <p className="text-gray-700"><strong>Email:</strong> <a href="mailto:Aarangjewels@gmail.com" className="text-blue-500">Aarangjewels@gmail.com</a></p>
              <p className="text-gray-700"><strong>Address:</strong> No.8, Vivekananda road, SBI staff housing colony, 3rd stage, 4th block, 8th main, Basaveshwara nagar, Bangalore - 560079</p>
            </div>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">4. Third-Party Content</h2>
            <p className="text-gray-700">
              While we strive to provide an accessible website, some content, such as third-party tools or widgets, may be outside our control. We encourage these third-party providers to offer accessible content, and we are happy to assist with accessibility issues related to third-party content where possible.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">5. Future Enhancements</h2>
            <p className="text-gray-700">
              Accessibility is an ongoing commitment for us. We may periodically update our accessibility policy to reflect improvements in our practices or advancements in technology that enhance accessibility on our website.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AccessibilityPolicy;
