import React from 'react';
import { Disclosure } from '@headlessui/react';
import { ChevronUpIcon } from '@heroicons/react/24/outline';

const faqs = [
  {
    question: "How can I place an order?",
    answer: "You can place an order by browsing our website, selecting the items you like, and adding them to your cart. Once you've made your selection, proceed to checkout and follow the instructions to complete your purchase."
  },
  {
    question: "What payment methods do you accept?",
    answer: "We accept only UPI payments and Cash on delivery."
  },
  {
    question: "How do I know if a piece of jewellery will fit me?",
    answer: "We offer detailed sizing information and photos of the jewellery worn by a model on each product page. If you have any questions or need further assistance, please contact our customer support team."
  },
  {
    question: "Can I return or exchange an item?",
    answer: "No return and refund policy for the orders. Only exchange upon terms. For more details, please review our return & exchange policy."
  },
  {
    question: "How do I care for my imitation jewellery?",
    answer: "To keep your imitation jewellery looking its best, avoid exposing it to water, perfumes, or harsh chemicals. Store it in a dry, cool place, and clean it with a soft cloth."
  },
//   {
//     question: "What is your shipping policy?",
//     answer: "We offer various shipping options, including standard and expedited delivery. Shipping times and costs depend on your location and the shipping method selected. Check our shipping policy page for more information."
//   },
//   {
//     question: "How can I track my order?",
//     answer: "Once your order has been shipped, you will receive a tracking number via email. Use this number to track your shipment on our website or through the carrier’s tracking system."
//   },
  {
    question: "What should I do if I receive a damaged or incorrect item?",
    answer: "If you receive a damaged or incorrect item, please contact our customer service team immediately. Provide your order number and details about the issue, and we will assist you with a return and exchange."
  }
];

function FAQ() {
  return (
    <div className="w-full max-w-2xl mx-auto p-4">
      <h1 className="text-3xl font-semibold text-gray-800 text-center mb-8">Frequently Asked Questions</h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <Disclosure key={index}>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex justify-between items-center w-full px-4 py-2 text-lg font-medium text-left text-gray-800 bg-gray-100 rounded-lg hover:bg-gray-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500 focus-visible:ring-opacity-75">
                  <span>{faq.question}</span>
                  <ChevronUpIcon className={`${open ? "transform rotate-180" : ""} w-5 h-5 text-purple-500`} />
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pt-4 pb-2 text-gray-700 text-sm">
                  {faq.answer}
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        ))}
      </div>
    </div>
  );
}

export default FAQ;
