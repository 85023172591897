// ProductsContext.js
import React, { createContext, useState, useEffect, useContext } from "react";
import * as XLSX from 'xlsx';

// Create the context
const ProductsContext = createContext();

// Custom hook to access ProductsContext data
export const useProducts = () => useContext(ProductsContext);

// Provider component that fetches and provides product data
export const ProductsProvider = ({ children }) => {
  const [products, setProducts] = useState([]);
  const [productsColection, setProductsColection] = useState([]);
  useEffect(() => {
    console.log("Fetching products data...");

    fetch('/assets/products.xlsx')  // Simulate fetching Excel file
      .then(response => response.arrayBuffer())
      .then(data => {
        console.log("Fetched data:", data);
        const workbook = XLSX.read(data, { type: 'array' });
        // const sheetName = workbook.SheetNames[1]; // Get the second sheet
        // const worksheet = workbook.Sheets[sheetName];
        
        // // Convert sheet to JSON
        // const json = XLSX.utils.sheet_to_json(worksheet);
        // setProducts(json); // Populate products data

        const sheetName = workbook.SheetNames[0]; // Get the first sheet
        const worksheet = workbook.Sheets[sheetName];
        
        // Convert sheet to JSON
        const json = XLSX.utils.sheet_to_json(worksheet);
        setProductsColection(json); // Populate products data
      })
      .catch(error => console.error("Error fetching data:", error));
  }, []); // Run only once on mount

  return (
    <ProductsContext.Provider value={{ products, setProducts,productsColection }}>
      {children}
    </ProductsContext.Provider>
  );
};
