/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { useProducts } from '../ProductsContext';
const products = [
  {
    "id": 1,
    "name": "Pink Yasmeen Jhumka",
    "category": "Earrings",
    "originalPrice": 1050,
    "description": "Description of the product goes here. Its a great product that you'll love!",
    "discountPrice": 545,
    "img": `/assets/images/necklace2.jpg`,
    "imageUrl":'necklace2',
    "subimage1": "necklace2",
    "subimage2": "necklace3",
    "subimage3": "necklace4",
    "hoverImageUrl": "necklace2",
    "active": "Y",
    "popularity": 150,
    "rating": 4.5,
    "date": "2024-01-10",
    "occasion": "officer",
    "qty": 1,
    "specifications_Finish": "Victorion AD stone",
    "specifications_Length": "6inch",
    "specifications_Weight": "4gms",
    "specifications_Fastening": "Adjustable Back Thread",
    "specifications_Color": "green",
    "specifications_Size": 7
},
{
  "id": 2,
  "name": "Avya Designer Earring",
  "category": "Earrings",
  "originalPrice": 100,
  "description": "Description of the product goes here. Its a great product that you'll love!",
  "discountPrice": 45,
  "img": `/assets/images/necklace1.jpg`,
  "imageUrl":'necklace1',
  "subimage1": "necklace2",
  "subimage2": "necklace3",
  "subimage3": "necklace4",
  "hoverImageUrl": "necklace2",
  "active": "Y",
  "popularity": 150,
  "rating": 4.5,
  "date": "2024-01-10",
  "occasion": "officer",
  "qty": 1,
  "specifications_Finish": "Victorion AD stone",
  "specifications_Length": "6inch",
  "specifications_Weight": "4gms",
  "specifications_Fastening": "Adjustable Back Thread",
  "specifications_Color": "green",
  "specifications_Size": 7
},
    // More products...
  ]
  const TrendingProducts = () => {
    const { productsColection } = useProducts();
    const navigate = useNavigate();
    const handleProductClick = (product) => {
      debugger;
     navigate(`/product/${product.id}`, { state: { product } });
    };
    return (
      <div className="bg-white">
        <div className="mx-auto max-w-2xl px-4 py-8 sm:px-6 sm:py-8 lg:max-w-7xl lg:px-8">
          <div className="flex justify-between">
          <h2 className="text-2xl font-bold  text-gray-900">Customers also purchased </h2>
          <a href="#" class="md  awm ayn bli ccq text-blue-600 font-semibold" style={{lineHeight:'1.25rem'}}>See everything<span aria-hidden="true"> →</span></a>
          </div>
          
          <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-4 xl:gap-x-8">
            {productsColection.map((product) => (
              <div key={product.id} className="group relativeold">
                <div    className="cursor-pointer aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80">
                  <img
                          onClick={() => handleProductClick(product)}
                    alt={`/assets/images/${product.hoverImageUrl}.jpg`}
                    src={`/assets/images/${product.imageUrl}.jpg`}
                    
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                  />
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700">
                      {/* <a href={product.href}> */}
                        {/* <span aria-hidden="true" className="absolute inset-0" /> */}
                        {product.name}
                      {/* </a> */}
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">{product.specifications_Color}</p>
                  </div>
                  <p className="text-sm font-medium text-gray-900">Rs. {product.discountPrice}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
  
export default TrendingProducts;
