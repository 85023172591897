import React from "react";

const Search = () => {
  return (
    <div className="bg-blue-100 min-h-screen flex flex-col">
      {/* Top Bar */}
      <div className="flex justify-between items-center px-4 py-2 bg-blue-200">
        <div className="text-sm font-medium">Brand Mall</div>
        <label className="inline-flex items-center cursor-pointer">
          <input type="checkbox" className="sr-only peer" />
          <div className="w-10 h-6 bg-gray-200 peer-focus:ring-blue-300 rounded-full peer peer-checked:bg-blue-500"></div>
          <span className="ml-2 text-sm font-medium">OFF</span>
        </label>
      </div>

      {/* Search Bar */}
      <div className="flex items-center px-4 py-2 bg-blue-300">
        <div className="flex-grow">
          <input
            type="text"
            placeholder="high ankle shoes men"
            className="w-full px-4 py-2 text-sm bg-white border rounded-full"
          />
        </div>
        <button className="ml-2">
          <i className="text-blue-500 material-icons">search</i>
        </button>
        <button className="ml-2">
          <i className="text-blue-500 material-icons">photo_camera</i>
        </button>
      </div>

      {/* Categories */}
      <div className="flex overflow-x-auto space-x-4 py-4 px-4 bg-white">
        {[
          { label: "Scan & Pay", icon: "🛒" },
          { label: "Fashion", icon: "👗" },
          { label: "Mobiles", icon: "📱" },
          { label: "Beauty", icon: "💄" },
          { label: "Gadgets", icon: "🎧" },
        ].map((item, index) => (
          <div
            key={index}
            className="flex flex-col items-center w-20 shrink-0"
          >
            <div className="w-14 h-14 flex items-center justify-center bg-pink-100 rounded-full">
              <span className="text-2xl">{item.icon}</span>
            </div>
            <div className="text-sm mt-1 text-center">{item.label}</div>
          </div>
        ))}
      </div>

      {/* Time Bomb Deals */}
      <div className="py-4 px-4 bg-gray-50">
        <h3 className="text-lg font-semibold mb-2">Time bomb deals</h3>
        <div className="grid grid-cols-2 gap-4">
          {[
            {
              title: "Red Tape",
              subtitle: "Min. 83% Off",
              image: "https://via.placeholder.com/150",
            },
            {
              title: "Fitted bedsheets",
              subtitle: "From ₹269",
              image: "https://via.placeholder.com/150",
            },
          ].map((item, index) => (
            <div
              key={index}
              className="flex flex-col items-center bg-white rounded-lg shadow p-2"
            >
              <img
                src={item.image}
                alt={item.title}
                className="w-full h-24 object-cover rounded"
              />
              <div className="mt-2 text-sm font-medium">{item.title}</div>
              <div className="text-xs text-gray-500">{item.subtitle}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Search;
