import React from "react";

const TermsOfService = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Terms of Service</h1>

        <p className="text-gray-700 mb-6">
          By accessing our website and purchasing our products, you agree to abide by our Terms of Service. Please read these terms carefully, as they outline your rights and obligations when using our services.
        </p>

        <section className="space-y-8">
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">1. Acceptance of Terms</h2>
            <p className="text-gray-700">
              By visiting our website and/or purchasing something from us, you engage in our "Service" and agree to be bound by the following terms and conditions. If you do not agree to these Terms of Service, you may not access our website or use any of our services.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">2. Orders and Payment</h2>
            <p className="text-gray-700 mb-4">
              The following terms apply to all orders placed on our website:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li><strong>Product Availability:</strong> All products are subject to availability. We reserve the right to discontinue any product at any time.</li>
              <li><strong>Pricing and Payment:</strong> Prices are listed in your local currency and may change without notice. Payments must be completed at the time of purchase. We accept the following payment methods:
                <ul className="list-inside list-disc pl-4">
                  <li>Credit/Debit Cards</li>
                  <li>PayPal</li>
                  <li>Other Payment Methods (If applicable)</li>
                </ul>
              </li>
              <li><strong>Order Confirmation:</strong> After placing an order, you will receive an order confirmation via email. This confirmation does not indicate our acceptance of your order but confirms we received it.</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">3. Shipping and Delivery</h2>
            <p className="text-gray-700 mb-4">
              Shipping times and availability depend on your location and the shipping method you choose. Please refer to our Shipping Policy for detailed information.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">4. Returns and Refunds</h2>
            <p className="text-gray-700 mb-4">
              We accept returns within [X days] from the date of delivery. Please review our Return Policy for more information on how to initiate a return or refund request.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">5. Limitation of Liability</h2>
            <p className="text-gray-700 mb-4">
              Our liability is limited to the amount you paid for the product or service. We are not liable for any indirect, incidental, or consequential damages resulting from the use of our services.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">6. Modifications to Terms</h2>
            <p className="text-gray-700 mb-4">
              We may update or modify these Terms of Service from time to time. Any changes will be posted on this page, and we recommend that you review the Terms periodically.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">7. Governing Law</h2>
            <p className="text-gray-700">
              These Terms of Service shall be governed by and construed in accordance with the laws of [Your Jurisdiction]. Any disputes arising out of or relating to these terms shall be resolved in the courts of [Your Jurisdiction].
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default TermsOfService;
