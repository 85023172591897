import React, { useState, useEffect } from 'react';
import ProductCardNew from './ProductCardNew';
//import * as XLSX from 'xlsx';
import { useCart } from './CartContext';
// {JSON.stringify(jsonData, null, 2)}

const productsold = [
  // { id: 1, name: 'Product 1', price: 29.99, imageUrl: 'https://via.placeholder.com/150' },
  // { id: 2, name: 'Product 2', price: 19.99, imageUrl: 'https://via.placeholder.com/150' },
  // { id: 3, name: 'Product 3', price: 39.99, imageUrl: 'https://via.placeholder.com/150' },
      
      {
        id: 1,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
        hoverImageUrl: "https://via.placeholder.com/150"
      },
      {
        id: 2,
        name: "Avya Designer Earring",
        originalPrice: 885,
        discountPrice: 445,
        imageUrl: "https://example.com/image2.jpg",
        hoverImageUrl: "https://example.com/hover-image2.jpg"
      },
      {
        id: 3,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
        hoverImageUrl: "https://via.placeholder.com/150"
      },
      {
        id: 4,
        name: "Pink Yasmeen Jhumka",
        originalPrice: 1050,
        discountPrice: 545,
        imageUrl: "https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg",
        hoverImageUrl: "https://via.placeholder.com/150"
      },
      // Add more products here
  
];

const ProductList = ({ products }) => {
  debugger;
  const {cart, addToCart ,removeFromCart } = useCart();
 // const [cart, setCart] = useState([]);
  const [jsonData, setJsonData] = useState([]);
  // useEffect(() => {
  //   // Fetch the Excel file from the public assets folder
  //   fetch('/assets/products.xlsx')
  //     .then(response => response.arrayBuffer())
  //     .then(data => {
  //       debugger;
  //       // Read the Excel file
  //       const workbook = XLSX.read(data, { type: 'array' });
  //       const sheetName = workbook.SheetNames[0]; // Get the first sheet
  //       const worksheet = workbook.Sheets[sheetName];
        
  //       // Convert sheet to JSON
  //       const json = XLSX.utils.sheet_to_json(worksheet);
  //       setJsonData(json);
  //     })
  //     .catch(error => console.error('Error fetching the Excel file:', error));
  // }, []);

//   // Load cart from localStorage when the component mounts
//   useEffect(() => {
//     const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
//     setCart(storedCart);
//   }, []);

//   // Save cart to localStorage whenever it changes
//   useEffect(() => {
//     localStorage.setItem('cart', JSON.stringify(cart));
//   }, [cart]);

//   // Add product to cart
//   const addToCart = (product) => {
//     if(isInCart(product.id)){
// alert('already added')
//     }else{
//       setCart((prevCart) => {
//         const updatedCart = [...prevCart, product];
//         return updatedCart;
//       });
//     }
    
//   };

//   // Remove product from cart
//   const removeFromCart = (productId) => {
//     setCart((prevCart) => {
//       const updatedCart = prevCart.filter((item) => item.id !== productId);
//       return updatedCart;
//     });
//   };

//   // Check if a product is already in the cart
//   const isInCart = (productId) => {
//     return cart.some((item) => item.id === productId);
//   };


  return (
    <div className="container mx-auto mt-10 lg:px-8 px-4 xs:px-2 mb-4">
      {/* <div className="grid grid-cols-1 md:grid-cols-3 gap-8"> */}
      <div className="mt-6 grid grid-cols-1  gap-x-6 gap-y-10 xs:grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
        {products.map(product => (
          <ProductCardNew key={product.id} product={product} addToCart={addToCart} removeFromCart={removeFromCart} cart={cart} active={product.active} />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
