// import React from 'react';
// import { Link } from 'react-router-dom';

// const ProductCard = ({ product }) => {
//   return (
//     <div className="border p-4 rounded-lg">
//       <img src={product.imageUrl} alt={product.name} className="h-40 w-full object-cover mb-4" />
//       <h3 className="text-lg font-bold mb-2">{product.name}</h3>
//       <p className="text-gray-500">${product.price.toFixed(2)}</p>
//       <Link to={`/product/${product.id}`} className="text-indigo-500 mt-4 block">
//         View Details
//       </Link>
//     </div>
//   );
// };

// export default ProductCard;


import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { FaHeart, FaRegHeart } from 'react-icons/fa';
import { AiOutlineShoppingCart } from 'react-icons/ai';
import '../components/Productcard.scss'
import { useNavigate } from "react-router-dom";

const ProductCardNew = ({ product ,addToCart,removeFromCart,cart,active}) => {
  const navigate = useNavigate();
  const [isFavorited, setIsFavorited] = useState(false);
  const [hover, setHover] = useState(false);
debugger;
  const isInCart = cart.some(cartItem => cartItem.id === product.id);
 // Handle product click
 const handleProductClick = (product) => {
  debugger;
 navigate(`/product/${product.id}`, { state: { product } });
};
  const toggleFavorite = () => setIsFavorited(!isFavorited);
  const images = 
    `/assets/images/${product.imageUrl}.jpg`;
  return (
    <div>
     {/* <motion.div 
    //   // className="w-80 p-4 shadow-lg rounded-lg bg-white"
    //   whileHover={{ scale: 1 }}
    //   onMouseEnter={() => setHover(true)}
    //   onMouseLeave={() => setHover(false)}
    // > */}
    
      

      {/* Product Image */}
      {/* <div className="relative h-60 w-full mt-4 mb-6">
        <motion.img 
          src={hover ? product.imageUrl : product.hoverImageUrl} 
          alt={product.name} 
          className="object-cover h-full w-full rounded-lg" 
        />
      </div> */}

              <div key={product.id} className="group relative">
                  
        <div className="absolute top-2 right-2 bg-white p-2 rounded-full shadow-md cursor-pointer z-1" onClick={toggleFavorite}>
          {isFavorited ? <FaHeart className="text-red-500" /> : <FaRegHeart />}
        </div>
              <div 
      className="relative aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none  lg:h-80 cursor-pointer"

   
     
    >

                {/* <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-80"> */}
  
                <img 
                 onClick={() => handleProductClick(product)}
                 
                  src={images}
                  alt={product.name} 
                    className="h-full w-full object-cover object-center lg:h-full lg:w-full"
                  />
             
                    {/* Wishlist Icon */}
    
                {/* </div> */}
                </div>
                <div className="mt-4 flex justify-between">
                  <div>
                    <h3 className="text-sm text-gray-700 font-medium">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolutew inset-0" />
                        {product.name}
                      </a>
                    </h3>
                    {/* <p className="mt-1 text-sm text-gray-500">{product.originalPrice}</p> */}
                  </div>
                  {/* <p className="text-sm font-medium text-gray-900">{product.discountPrice}</p> */}
                     {/* Wishlist Icon */}
     

                </div>
              </div>
            
     

      {/* Product Title */}
      

      {/* Price with Strike-through and Discount Price */}
      <div className="flex items-center space-x-3 mb-4">
        {/* <span className="text-gray-400 line-through">${product.originalPrice}</span> */}
        {product.originalPrice!=product.discountPrice &&  <p className="mt-1 text-sm text-gray-500 line-through">Rs. {product.originalPrice}</p>}
       
        <p className="text-sm font-medium text-gray-900 ">Rs. {product.discountPrice}</p>       
        {/* <span className="text-lg text-green-500 font-semibold">${product.discountPrice}</span> */}
            {/* Add to Cart Button */}
    
      </div>
      <motion.div 
      // className="w-80 p-4 shadow-lg rounded-lg bg-white"
      whileHover={{ scale: 1 }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
    {(active=="n" ||active=="N")?<div className='text-gray-500'>Out Of Stock</div> :
    (isInCart && (active=="Y" ||active=="y"))? (
           <motion.button 
           whileHover={{ scale: 1.05,backgroundColor: 'rgba(229, 231, 235 , 1)' }}
           style={{backgroundColor:'#f4a261'}}
           className="w-full py-2 px-4 bg-gray-100old bg-orange-500  text-white rounded-lg flex items-center justify-center space-x-2"
           onClick={() => removeFromCart(product)}
         >
           <AiOutlineShoppingCart className="text-xl" />
           <span className='text-gray-900'>Already added</span>
         </motion.button>
      ) : (
      <motion.button 
        whileHover={{ scale: 1.05,backgroundColor: 'rgba(229, 231, 235 , 1)' }}
        className="w-full py-2 px-4 bg-gray-100  text-blue-600 rounded-lg flex items-center justify-center space-x-2"
        onClick={() => addToCart(product)}
      >
        <AiOutlineShoppingCart className="text-xl" />
        <span className='text-gray-900'>Add to Bag</span>
      </motion.button>)
}
      </motion.div> 
      </div>
  );
};

export default ProductCardNew;

// Sample Usage
// Pass a product object to the ProductCard component like this:

// const product = {
//   name: 'Zip Tote Basket',
//   image: 'link-to-image1.jpg',
//   hoverImage: 'link-to-hover-image.jpg',
//   originalPrice: 140,
//   discountPrice: 120,
// };

// <ProductCard product={product} />
