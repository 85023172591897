import React from "react";

const ShippingAndDelivery = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Shipping and Delivery</h1>

        <p className="text-gray-700 mb-6">
          We ship products within the country and aim to process orders within 7 business days. Shipping times may vary depending on your location and the shipping option you choose at checkout. Please review the following details about our shipping policy.
        </p>

        <section className="space-y-8">
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">1. Order Processing</h2>
            <p className="text-gray-700">
              Once your order is placed, it will be processed within 7 business days. We will notify you via email when your order is shipped or if there are any delays.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">2. Shipping Time</h2>
            <p className="text-gray-700">
              Shipping times may vary depending on your location and the shipping method selected during checkout. On average, orders are delivered within 5-10 business days after processing.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">3. Shipping Options</h2>
            <p className="text-gray-700 mb-4">
              We offer a range of shipping options to accommodate different delivery speeds and budget preferences. The available shipping methods will be displayed during checkout.
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Standard Shipping (5-10 business days)</li>
              <li>Expedited Shipping (2-5 business days)</li>
              <li>Express Shipping (1-2 business days)</li>
            </ul>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">4. Shipping Costs</h2>
            <p className="text-gray-700">
              Shipping costs are calculated based on your location and the shipping method selected. The final shipping cost will be displayed during checkout before payment is processed.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">5. Delivery Delays</h2>
            <p className="text-gray-700">
              While we make every effort to ship your order promptly, we are not responsible for any delays caused by the shipping carrier. Once your order has been shipped, any issues regarding delivery times or missed deliveries should be directed to the carrier.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">6. International Shipping</h2>
            <p className="text-gray-700">
              Currently, we only ship within the country. We do not offer international shipping at this time. If you are located outside the shipping area, we recommend checking back later for any updates to our shipping policy.
            </p>
          </div>

          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">7. Tracking Your Order</h2>
            <p className="text-gray-700">
              Once your order has been shipped, we will send you a tracking number via email so you can track your package’s journey until it reaches you.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ShippingAndDelivery;
