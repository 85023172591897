import React from "react";

const ReturnExchangePolicy = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-md rounded-md my-8">
    <h1 className="text-2xl font-bold text-gray-800 mb-4">Return & Exchange Policy</h1>
    <p className="text-gray-700 mb-4">
      No return and refund policy for the orders. Only exchange upon terms:
    </p>
    <p className="text-gray-700 mb-4">
      Please record a video of the unpacking process in case of damage to the item. This video is essential for processing an exchange order.
    </p>

    <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Eligibility for Returns and Exchanges:</h2>
    <ul className="list-disc list-inside text-gray-700 mb-4">
      <li>Returns and exchanges are accepted within 48 hours from the date of delivery.</li>
      <li>Items must be in their original, unused condition with all packaging intact.</li>
    </ul>

    <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Exchange Procedure:</h2>
    <ul className="list-disc list-inside text-gray-700 mb-4">
      <li>Exchanges are subject to availability. If the requested item is out of stock, a refund will be issued instead.</li>
      <li>The exchanged item will be shipped to you at no additional cost.</li>
    </ul>

    <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Refunds:</h2>
    <ul className="list-disc list-inside text-gray-700 mb-4">
      <li>Refunds will be processed to the original payment method once the returned item is received and inspected.</li>
      <li>Refund processing time may vary depending on the payment method and bank policies.</li>
    </ul>

    <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Defective or Incorrect Items:</h2>
    <p className="text-gray-700 mb-4">
      If you receive a defective or incorrect item, please notify customer support immediately. We will arrange for a replacement and cover the return shipping costs.
    </p>

    <h2 className="text-xl font-semibold text-gray-800 mt-6 mb-2">Packaging for Returns:</h2>
    <p className="text-gray-700 mb-4">
      Ensure that the item is securely packaged to prevent damage during return/exchange shipping. Include a copy of the invoice or order confirmation inside the package.
    </p>
  </div>
  );
};

export default ReturnExchangePolicy;
