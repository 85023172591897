/*
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/aspect-ratio'),
    ],
  }
  ```
*/
'use client'

import { Fragment, useState } from 'react'
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  Popover,
  PopoverButton,
  PopoverGroup,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
} from '@headlessui/react'
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'
import CartCount from '../CartCount';
import { useNavigate } from "react-router-dom";
const navigation = {
  categories: [
    {
      id: '1',
      name: 'Shop by collections',
      featured: [
        {
          name: 'New Arrivals',
          href: '/product/?value=Earrings&name=Earrings',
          imageSrc: `/assets/images/Kayal_Oxidised_Earring.jpg`,
          imageAlt: 'New Arrivals',
        },
        {
          name: 'New Arrivals',
          href: '/product/?value=Neckwear&name=Neckwear/Mala',
          imageSrc: `/assets/images/Shriya_Kundan_Necklace_Earrings_Green.jpg`,
          imageAlt: 'New Arrivals',
        }
      ],
      sections: [
        {
          id: 'collections',
          name: 'Collections',
          items: [
            { name: 'Earrings', href: '/product/?value=Earrings&name=Earrings' },
            { name: 'Neckwear/Mala', href: '/product/?value=Neckwear&name=Neckwear/Mala' },///imageslider
            { name: 'Bracelets/Bangles', href: '/product/?value=Bangles&name=Bracelets/Bangles' },
            // { name: 'Nose rings', href: '/product/?value=noserings&name=Nose rings' },       
          ],
        },
    
      ],
    },
    // {
    //   id: '2',
    //   name: 'Shop by categories',
    //   featured: [
    //     {
    //       name: 'New Arrivals',
    //       href: '/product/?value=neckwearmala&name=Neckwear/Mala',
    //       imageSrc: `/assets/images/necklace1.jpg`,
    //       imageAlt: 'New Arrivals',
    //     },
    //     {
    //       name: 'New Arrivals',
    //       href: '/product/?value=neckwearmala&name=Neckwear/Mala',
    //       imageSrc: `/assets/images/necklace4.jpg`,
    //       imageAlt: 'New Arrivals',
    //     }
    //   ],
    //   sections: [
    //     {
    //       id: 'Categories',
    //       name: 'Categories',
    //       items: [
    //         { name: 'Statement Jewellery', href: '#' },
    //         { name: 'Kundan', href: '#' },
    //         { name: 'Oxidised Silver', href: '#' },
    //         { name: 'Contemporary', href: '#' },
    //         { name: 'Antique', href: '#' },
    //         { name: 'Kemp', href: '#' },           
    //       ],
    //     },
   
    //   ],
    // },
  ],
  pages: [
    { name: 'New Arrivals', href: '/product/?name=New Seller&loadcategory=New Arrival' },
    { name: 'Best Sellers', href: '#' },
    { name: 'FAQ', href: '/faq' },
  ],
}

export default function Example() {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate();
const handleHomePage=()=>{
navigate("/");
}
  return (
    <div className="bg-white">
      {/* Mobile menu */}
      <Dialog open={open} onClose={setOpen} className="relative z-40 lg:hidden">
        <DialogBackdrop
          transition
          className="fixed inset-0 bg-black bg-opacity-25 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
        />

        <div className="fixed inset-0 z-40 flex">
          <DialogPanel
            transition
            className="relative flex w-full max-w-xs transform flex-col overflow-y-auto bg-white pb-12 shadow-xl transition duration-300 ease-in-out data-[closed]:-translate-x-full"
          >
            <div className="flex px-4 pb-2 pt-5">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="relative -m-2 inline-flex items-center justify-center rounded-md p-2 text-gray-400"
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Close menu</span>
                <XMarkIcon aria-hidden="true" className="h-6 w-6" />
              </button>
            </div>

            {/* Links */}
            <TabGroup className="mt-2">
              <div className="border-b border-gray-200old border-navbarborder-500">
                <TabList className="-mb-px flex space-x-8 px-4 lg:hidden">
                  {navigation.categories.map((category) => (
                    <Tab
                      key={category.name}
                      className="flex-1 whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-base font-medium text-gray-900 data-[selected]:border-blue-600 data-[selected]:text-blue-600"
                    >
                      {category.name}
                    </Tab>
                  ))}
                </TabList>

                <TabList className="-mb-px flex space-x-8 px-4 hidden  lg:block">
                  {navigation.categories.map((category) => (
                    <Tab
                      key={category.name}
                      className="flex-1 whitespace-nowrap border-b-2 border-transparent px-1 py-4 text-base font-medium text-gray-900 data-[selected]:border-indigo-600 data-[selected]:text-indigo-600"
                    >
                      {category.name}
                    </Tab>
                  ))}
                </TabList>
              </div>
              <TabPanels as={Fragment}>
                {navigation.categories.map((category) => (
                  <TabPanel key={category.name} className="space-y-10 px-4 pb-8 pt-10">
                    <div className="grid grid-cols-2 gap-x-4">
                      {category.featured.map((item) => (
                        <div key={item.name} className="group relative text-sm">
                          <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                            <img alt={item.imageAlt} src={item.imageSrc} className="object-cover object-center" />
                          </div>
                          <a href={item.href} className="mt-6 block font-medium text-gray-900">
                            <span aria-hidden="true" className="absolute inset-0 z-10" />
                            {item.name}
                          </a>
                          <p aria-hidden="true" className="mt-1">
                            Shop now
                          </p>
                        </div>
                      ))}
                    </div>
                    {category.sections.map((section) => (
                      <div key={section.name}>
                        <p id={`${category.id}-${section.id}-heading-mobile`} className="font-medium text-gray-900">
                          {section.name}
                        </p>
                        <ul
                          role="list"
                          aria-labelledby={`${category.id}-${section.id}-heading-mobile`}
                          className="mt-6 flex flex-col space-y-6"
                        >
                          {section.items.map((item) => (
                            <li key={item.name} className="flow-root">
                              <a href={item.href} className="-m-2 block p-2 text-gray-500">
                                {item.name}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </TabPanel>
                ))}
              </TabPanels>
            </TabGroup>

            <div className="space-y-6 border-t border-gray-200 px-4 py-6">
              {navigation.pages.map((page) => (
                <div key={page.name} className="flow-root">
                  <a href={page.href} className="-m-2 block p-2 font-medium text-gray-900">
                    {page.name}
                  </a>
                </div>
              ))}
            </div>

            {/* <div className="space-y-6 border-t border-gray-200 px-4 py-6">
              <div className="flow-root">
                <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                  Sign in
                </a>
              </div>
              <div className="flow-root">
                <a href="#" className="-m-2 block p-2 font-medium text-gray-900">
                  Create account
                </a>
              </div>
            </div> */}

            <div className="border-t border-gray-200 px-4 py-6">
              <a href="#" className="-m-2 flex items-center p-2">
                {/* <img
                  alt=""
                  src="https://tailwindui.com/img/flags/flag-india.svg"
                  className="block h-auto w-5 flex-shrink-0"
                /> */}
                 <svg class="block h-auto w-5 flex-shrink-0" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256"/><path d="m256 0c-101.494 0-189.19 59.065-230.598 144.696h461.195c-41.407-85.631-129.104-144.696-230.597-144.696z" fill="#ff9811"/><path d="m256 512c101.493 0 189.19-59.065 230.598-144.696h-461.196c41.408 85.631 129.104 144.696 230.598 144.696z" fill="#6da544"/><circle cx="256" cy="256" fill="#0052b4" r="89.043"/><circle cx="256" cy="256" fill="#f0f0f0" r="55.652"/><path d="m256 187.326 17.169 38.938 42.304-4.601-25.136 34.337 25.136 34.337-42.304-4.601-17.169 38.938-17.169-38.938-42.304 4.6 25.136-34.336-25.136-34.337 42.304 4.601z" fill="#0052b4"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                <span className="ml-3 block text-base font-medium text-gray-900">IND</span>
                <span className="sr-only">, change currency</span>
              </a>
            </div>
          </DialogPanel>
        </div>
      </Dialog>

      <header className="relative bg-white z-10 ">
        {/* <p className="flex h-10 items-center justify-center bg-indigo-600 px-4 text-sm font-medium text-white sm:px-6 lg:px-8">
          Get free delivery on orders over $100
        </p> */}

        <nav aria-label="Top" className="mx-auto max-w-7xlold px-4 sm:px-6 lg:px-8 bg-blue-600">
          <div className="border-b border-gray-200old border-navbarborder-500">
            <div className="flex h-16 items-center">
              <button
                type="button"
                onClick={() => setOpen(true)}
                className="relative rounded-md bg-white p-2 text-gray-400 lg:hidden"
              >
                <span className="absolute -inset-0.5" />
                <span className="sr-only">Open menu</span>
                <Bars3Icon aria-hidden="true" className="h-6 w-6" />
              </button>

              {/* Logo */}
              {/* <div className="ml-4 flex lg:ml-0">
                <a href="#">
                  <span className="sr-only">Your Company</span>
                  <img
                    alt=""
                    src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
                    className="h-8 w-auto"
                  />
                  <span className="text-indigo-600 text-lg font-bold">AARANG</span>
                </a>
              </div> */}

              <div className="flex items-center cursor-pointer ml-2" onClick={handleHomePage}>
          <img
            src="/logo.png"
            alt="Company Logo"
            className="h-8 w-8 mr-2"
          />
          <span className="text-indigo-600 text-lg font-bold">Aarang Jewels</span>
        </div>

              {/* Flyout menus */}
              <PopoverGroup className="hidden lg:ml-8 lg:block lg:self-stretch">
                <div className="flex h-full space-x-8">
                  {navigation.categories.map((category) => (
                    <Popover key={category.name} className="flex" style={{outline:'none'}}>
                      <div className="relative flex " >
                        <PopoverButton className="relative z-10 -mb-px flex items-center border-b-2 border-transparent pt-px text-sm font-medium text-indigo-600 transition-colors duration-200 ease-out hover:text-gray-800 data-[open]:border-indigo-600 data-[open]:text-indigo-600">
                          {category.name}
                        </PopoverButton>
                      </div>

                      <PopoverPanel
                        transition
                        className="absolute inset-x-0 top-full text-sm text-gray-500 transition data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        {/* Presentational element used to render the bottom shadow, if we put the shadow on the actual panel it pokes out the top, so we use this shorter element to hide the top of the shadow */}
                        <div aria-hidden="true" className="absolute inset-0 top-1/2 bg-white shadow" />

                        <div className="relative bg-white">
                          <div className="mx-auto max-w-7xl px-8">
                            <div className="grid grid-cols-2 gap-x-8 gap-y-10 py-16">
                              <div className="col-start-2 grid grid-cols-2 gap-x-8">
                                {category.featured.map((item) => (
                                  <div key={item.name} className="group relative text-base sm:text-sm">
                                    <div className="aspect-h-1 aspect-w-1 overflow-hidden rounded-lg bg-gray-100 group-hover:opacity-75">
                                      <img
                                        alt={item.imageAlt}
                                        src={item.imageSrc}
                                        className="object-cover object-center"
                                      />
                                    </div>
                                    <a href={item.href} className="mt-6 block font-medium text-gray-900">
                                      <span aria-hidden="true" className="absolute inset-0 z-10" />
                                      {item.name}
                                    </a>
                                    <p aria-hidden="true" className="mt-1">
                                      Shop now
                                    </p>
                                  </div>
                                ))}
                              </div>
                              <div className="row-start-1 grid grid-cols-3 gap-x-8 gap-y-10 text-sm">
                                {category.sections.map((section) => (
                                  <div key={section.name}>
                                    <p id={`${section.name}-heading`} className="font-medium text-gray-900">
                                      {section.name}
                                    </p>
                                    <ul
                                      role="list"
                                      aria-labelledby={`${section.name}-heading`}
                                      className="mt-6 space-y-6 sm:mt-4 sm:space-y-4"
                                    >
                                      {section.items.map((item) => (
                                        <li key={item.name} className="flex">
                                          <a href={item.href} className="hover:text-gray-800">
                                            {item.name}
                                          </a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </PopoverPanel>
                    </Popover>
                  ))}

                  {navigation.pages.map((page) => (
                    <a
                      key={page.name}
                      href={page.href}
                      className="flex items-center text-sm font-medium text-stone-700 hover:text-gray-800"
                    >
                      {page.name}
                    </a>
                  ))}
                </div>
              </PopoverGroup>

              <div className="ml-auto flex items-center">
                {/* <div className="hidden lg:flex lg:flex-1 lg:items-center lg:justify-end lg:space-x-6">
                  <a href="#" className="text-sm font-medium text-gray-700 hover:text-gray-800">
                    Sign in
                  </a>
                  <span aria-hidden="true" className="h-6 w-px bg-gray-200" />
                  <a href="#" className="text-sm font-medium text-gray-700 hover:text-gray-800">
                    Create account
                  </a>
                </div> */}

                <div className="hidden lg:ml-8 lg:flex">
                  <a href="#" className="flex items-center text-white hover:text-black">
                    {/* <img
                      alt=""
                      src="https://tailwindui.com/img/flags/flag-india.svg"
                      className="block h-auto w-5 flex-shrink-0"
                    /> */}
                    <svg class="block h-auto w-5 flex-shrink-0" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#f0f0f0" r="256"/><path d="m256 0c-101.494 0-189.19 59.065-230.598 144.696h461.195c-41.407-85.631-129.104-144.696-230.597-144.696z" fill="#ff9811"/><path d="m256 512c101.493 0 189.19-59.065 230.598-144.696h-461.196c41.408 85.631 129.104 144.696 230.598 144.696z" fill="#6da544"/><circle cx="256" cy="256" fill="#0052b4" r="89.043"/><circle cx="256" cy="256" fill="#f0f0f0" r="55.652"/><path d="m256 187.326 17.169 38.938 42.304-4.601-25.136 34.337 25.136 34.337-42.304-4.601-17.169 38.938-17.169-38.938-42.304 4.6 25.136-34.336-25.136-34.337 42.304 4.601z" fill="#0052b4"/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/><g/></svg>
                    <span className="ml-3 block text-sm font-medium">INR</span>
                    <span className="sr-only">, change currency</span>
                  </a>
                </div>

                {/* Search */}
                {/* <div className="flex lg:ml-6">
                  <a href="#" className="p-2 text-gray-400 hover:text-gray-500">
                    <span className="sr-only">Search</span>
                    <MagnifyingGlassIcon aria-hidden="true" className="h-6 w-6" />
                  </a>
                </div> */}

                {/* Cart */}
        
<CartCount></CartCount>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  )
}
