
// Cart.js
import React from 'react';
import { useCart } from './CartContext';
import OrderSummary from './OrderSummary';
import CheckoutForm from './CheckoutForm'

import { useState } from 'react'
import { Dialog, DialogBackdrop, DialogPanel, DialogTitle } from '@headlessui/react'
import { XMarkIcon } from '@heroicons/react/24/outline'

const Cart = () => {
  const { cart, removeFromCart, clearCart } = useCart();
  //const totalPrice = cartItems.reduce((total, item) => total + item.price * item.quantity, 0);
  const [orderComplete, setOrderComplete] = useState(false);
  
  
  const total = cart.reduce((sum, item) => sum + (item.discountPrice * (isNaN(item.qty)?0:item.qty)), 0).toFixed(2);
    const totalItems=cart.reduce((sum, item) => sum + (isNaN(item.qty)?0:item.qty), 0);
    const [orderTotal, setOrderTotal] = useState(total);
  const handleorderFormSubmit = (total) => {
    debugger;
    setOrderComplete(true);
    setOrderTotal(total);
  };
debugger;
  if (cart.length === 0) {
    return <h2 className="text-center text-xl">Your cart is empty</h2>;
  }

  return (
 <div className="flex h-full flex-col overflow-y-scroll bg-white shadow-xl">
                <div className="flex-1 overflow-y-auto px-4 py-6 sm:px-6">
               

                  <div className="flex flex-col lg:flex-row lg:space-x-8">
      {/* Left Section: Cart Items */}
      <div className="lg:w-1/2 w-full mb-3">
      <div className="flex items-start justify-between">
                    <div className="text-lg font-medium text-gray-900">Shopping cart</div>
           
                  </div>
                  <div className="mt-8">
                    <div className="flow-root">
                      <ul role="list" className="-my-6 divide-y divide-gray-200">
                        {cart.map((product) => (
                          <li key={product.id} className="flex py-6">
                            <div className="h-24 w-24 flex-shrink-0 overflow-hidden rounded-md border border-gray-200">
                              <img
                                alt={product.hoverImageUrl}
                                src={`/assets/images/${product.imageUrl}.jpg` }
                                className="h-full w-full object-cover object-center"
                              />
                            </div>

                            <div className="ml-4 flex flex-1 flex-col">
                              <div>
                                <div className="flex justify-between text-base font-medium text-gray-900">
                                  <h3>
                                    <a href={product.href}>{product.name}</a>
                                  </h3>
                                  <p className="ml-4">{product.discountPrice}</p>
                                </div>
                                <p className="mt-1 text-sm text-gray-500">{product.name}</p>
                              </div>
                              <div className="flex flex-1 items-end justify-between text-sm">
                                <p className="text-gray-500">Qty <span className='text-gray-900 font-medium' >{product.qty}</span></p>
                                
                                <div className="flex">
                                  <button type="button" 
                                     onClick={() => removeFromCart(product)}
                                  className="font-medium text-blue-600 hover:text-indigo-500">
                                    Remove
                                  </button>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
</div>
   {/* Right Section: Order Summary */}
   <div className="lg:w-1/2 w-full">
 
   {!orderComplete ? (
            <>
              {/* <OrderSummary
                subtotal={subtotal}
                shipping={shipping}
                tax={tax}
                onCheckout={handleFormSubmit}
              /> */}
                 <OrderSummary  handleorderFormSubmit={handleorderFormSubmit} total={total} totalItems={totalItems}/>   
              
            </>
          ) : (
            <>
             {/* <CheckoutForm /> onFormSubmit={handleFormSubmit}  */}
             <CheckoutForm amount={total} cartdetail={cart}/>
            
            </>           
          )}
       
      </div>
    </div>

                </div>

               
              </div>
             
           
  )

};

export default Cart;

// return (
//   <div className="container mx-auto p-4">
//     <h1 className="text-2xl mb-4">Shopping Cart</h1>
//     <div className="bg-white shadow-md rounded-lg p-4">
//       {cart.map((item) => (
//         <div key={item.id} className="flex justify-between items-center mb-4">
//           <div>
//             <h2 className="font-bold">{item.name}</h2>
//             <p>${item.discountPrice.toFixed(2)}</p>
//           </div>
//           <button
//             onClick={() => removeFromCart(item.id)}
//             className="text-red-500 hover:text-red-700"
//           >
//             Remove
//           </button>
//         </div>
//       ))}
//       <div className="flex justify-between mt-4">
//         <button
//           onClick={clearCart}
//           className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
//         >
//           Clear Cart
//         </button>
//         <OrderSummary />
//       </div>
//     </div>
//   </div>
// );