// src/RefundPolicy.js

import React from 'react';

const RefundPolicy = () => {
  return (
    <div className="max-w-3xl mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Refund Policy</h1>
      <p className="mb-4">
        Thank you for shopping with us. If you are not entirely satisfied with your purchase, we're here to help.
      </p>
      <h2 className="text-2xl font-semibold mt-6 mb-2">Returns</h2>
      <p className="mb-4">
        You have 30 calendar days to return an item from the date you received it. To be eligible for a return, your item must be unused and in the same condition that you received it.
      </p>
      
      <h2 className="text-2xl font-semibold mt-6 mb-2">Refunds</h2>
      <p className="mb-4">
        Once we receive your item, we will inspect it and notify you that we have received your returned item. We will immediately notify you on the status of your refund after inspecting the item.
      </p>
      <p className="mb-4">
        If your return is approved, we will initiate a refund to your original method of payment. You will receive the credit within a certain amount of days, depending on your card issuer's policies.
      </p>
      
      <h2 className="text-2xl font-semibold mt-6 mb-2">Exchanges</h2>
      <p className="mb-4">
        If you need to exchange an item for the same item, send us an email at support@example.com.
      </p>
      
      <h2 className="text-2xl font-semibold mt-6 mb-2">Shipping</h2>
      <p className="mb-4">
        You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable.
      </p>
      <p>
        If you have any questions about our Refund Policy, please contact us.
      </p>
    </div>
  );
};

export default RefundPolicy;
