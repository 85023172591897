import React, { useState } from "react";
import { QrReader } from "react-qr-reader";

const QRCode = () => {
  const [result, setResult] = useState("");
  const [error, setError] = useState("");

  const handleScan = (data) => {
    if (data) {
      setResult(data);
      console.log("QR Code Scanned: ", data);
    }
  };

  const handleError = (err) => {
    console.error("Error scanning QR Code: ", err);
    setError("Unable to access camera or scan QR code.");
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100 px-4">
      {/* Title */}
      <h1 className="text-2xl font-semibold mb-4 text-blue-600">
        Scan QR Code
      </h1>

      {/* QR Scanner */}
      <div className="w-full max-w-md">
        <QrReader
          delay={300}
          onError={handleError}
          onScan={handleScan}
          facingMode="environment" // Back camera
//           "user": Front camera.
// "environment": Back camera.
          className="rounded-lg border border-gray-300 shadow-lg"
          style={{ width: "100%", height: "auto" }}
        />
      </div>

      {/* Scanned Result */}
      <div className="mt-4 text-center">
        {result ? (
          <p className="text-green-500 font-medium">
            QR Code Result: <span className="font-bold">{result}</span>
          </p>
        ) : error ? (
          <p className="text-red-500">{error}</p>
        ) : (
          <p className="text-gray-500">Point your camera at a QR code.</p>
        )}
      </div>

      {/* Instructions */}
      <div className="mt-6 text-center text-gray-500">
        <p>
          Ensure you grant camera access for scanning. Use your back camera for
          better focus.
        </p>
      </div>
    </div>
  );
};

export default QRCode;
