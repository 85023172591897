// OrderSummary.js
import React from 'react';
//import { useCart } from './CartContext';

const OrderSummary = ({handleorderFormSubmit,total,totalItems}) => {
//   const { cart } = useCart();

//   const total = cart.reduce((sum, item) => sum + item.discountPrice * isNaN(item.qty)?0:item.qty, 0).toFixed(2);
//     const totalItems=cart.reduce((sum, item) => sum + isNaN(item.qty)?0:item.qty, 0);
  const handleCheckout = () => {
   // alert('Proceeding to checkout...');
    handleorderFormSubmit(total)
  };

  return (
    <div className=" bg-gray-50 p-4 rounded-lg">
      <h2 className="font-medium py-4 text-gray-900">Order Summary</h2>
      <div className="border-t border-gray-200 pb-2  py-6 sm:px-6old">
                  <div className="flex justify-between text-base font-medium  pb-3 ">
                    <p className='text-gray-600 text-sm'>Price({totalItems} item)</p>
                    <p className='text-gray-900 text-sm' >{total}</p>
                  </div>
                  <div className="border-t border-gray-200  py-6 pb-2 ">
<div className="flex justify-between text-base font-medium ">
                    <p className='text-gray-900 '>Total</p>
                    <p className='text-gray-900' >{total}</p>
                  </div>
</div>

                  <p className="mt-0.5 text-sm text-gray-500">Shipping and taxes calculated at checkout.</p>
                  <div className="mt-6">
                    <a
                      href="#"
                      onClick={handleCheckout}
                      className="flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-6 py-3 text-base font-medium text-black shadow-sm hover:bg-blue-600 hover:text-indigo-600"
                    >
                      Checkout
                    </a>
                  </div>
                  <div className="mt-6 flex justify-center text-center text-sm text-gray-500">
                    <p>
                      or{' '}
                      <a
                      href="/"
                       // onClick={() => setOpen(false)}
                        className="font-medium text-indigo-500 hover:text-blue-600"
                      >
                        Continue Shopping
                        <span aria-hidden="true"> &rarr;</span>
                      </a>
                    </p>
                  </div>
                </div>
    </div>
  );
};

export default OrderSummary;
