import React from 'react';
import '../NavBar/NavBar.scss'
const Footer = () => {
  return (
    <footer className="bg-footerbg-500 text-gray-300 py-10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="md:grid md:grid-cols-4 md:gap-8">
          {/* Company Mission */}
          <div className="space-y-8old">
            <h3 className="text-sm/6 font-semibold text-white tracking-wider">Our Mission</h3>
            <p className="text-sm/6 leading-9 text-stone-700" style={{marginTop:'1.3rem !important'}}>
              At Aarang Jewels, we are dedicated to providing the best quality products at the best prices. Our mission is to bring joy and comfort to our customers' lives through exceptional service and innovative products.
            </p>
          </div>

          {/* Shop */}
          <div className="mt-12 md:mt-0 space-y-4">
            <h3 className="text-sm font-semibold text-white tracking-wider uppercase">Shop</h3>
            <ul className='acf text-stone-700'>
              <li>
                <a href="#" className="text-sm hover:text-white">
                Shop by collections
                </a>
              </li>
              {/* <li>
                <a href="#" className="text-sm hover:text-white">
                Shop by categories
                </a>
              </li> */}
              <li>
                <a href="#" className="text-sm hover:text-white">
                New Arrivals
                </a>
              </li>
              <li>
                <a href="#" className="text-sm hover:text-white">
                Best Sellers
                </a>
              </li>
            </ul>
          </div>

          {/* Customer Service */}
          <div className="mt-12 md:mt-0 space-y-4">
            <h3 className="text-sm/6 font-semibold text-white tracking-wider">Customer Service</h3>
            <ul className='acf text-stone-700'>
              <li>
                <a href="/contactus" className="text-sm hover:text-white">
                  Contact Us
                </a>
              </li>
              <li>
                <a href="/ordertrack" className="text-sm hover:text-white">
                  Order Tracking
                </a>
              </li>
              <li>
                <a href="/shipdelivery" className="text-sm hover:text-white">
                  Shipping & Delivery
                </a>
              </li>
              <li>
                <a href="/returnexchange" className="text-sm hover:text-white">
                  Returns & Exchanges
                </a>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div className="mt-12 md:mt-0 space-y-4">
            <h3 className="text-sm/6 font-semibold text-white tracking-wider">Legal</h3>
            <ul className='acf text-stone-700'>
              <li>
                <a href="/privatepolicy" className="text-sm hover:text-white">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/termsservice" className="text-sm hover:text-white">
                  Terms of Service
                </a>
              </li>
              <li>
                <a href="/accessbilityservice" className="text-sm hover:text-white">
                  Accessibility
                </a>
              </li>
              <li>
                <a href="/returnexchange" className="text-sm hover:text-white">
                  Refund Policy
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="mt-8 border-t border-gray-700old border-navbarborder-500 pt-8">
          <p className="text-center text-sm/6 font-semibold  text-stone-700">
            &copy; 2024 Aarang Jewels. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
