import React, { useEffect, useState } from 'react';
import { useCart } from './CartContext';
import { Bars3Icon, MagnifyingGlassIcon, ShoppingBagIcon, XMarkIcon } from '@heroicons/react/24/outline'
import { useNavigate } from "react-router-dom";
const CartCount = () => {
  const { cart } = useCart();
  const navigate = useNavigate();
  const goToCart = () => {
    debugger;
   navigate(`/cart/`);
  };
  // // Load cart count from localStorage when the component mounts
  // useEffect(() => {
  //   const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
  //   debugger;
  //   setCartCount(storedCart.length);
  // }, []);

  // // Update cart count on localStorage change
  // useEffect(() => {
  //   const handleStorageChange = () => {
  //     debugger;
  //     const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
  //     setCartCount(storedCart.length);
  //   };

  //   window.addEventListener('storage', handleStorageChange);

  //   // Cleanup listener on unmount
  //   return () => {
  //     window.removeEventListener('storage', handleStorageChange);
  //   };
  // }, []);

  return (
    // <div className="text-xl font-bold">
    //   Cart Count: {cartCount}
    // </div>
            <div className="ml-4 flow-root lg:ml-6">
            <a href="#" className="group -m-2 flex items-center p-2" onClick={goToCart}>
              <ShoppingBagIcon
                aria-hidden="true"
                className="h-6 w-6 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
              />
              <span className="ml-2 text-sm font-medium text-white group-hover:text-black">{cart.length}</span>
              <span className="sr-only">items in cart, view bag</span>
            </a>
          </div>
  );
};

export default CartCount;
