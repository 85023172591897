import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Privacy Policy</h1>
        <p className="text-gray-700 mb-4">
          Aarang Jewels values your privacy and is committed to protecting your personal information. This Privacy Policy explains how we collect, use, and protect your data when you visit our website, make a purchase, or engage with us in other ways.
        </p>

        <section>
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">1. Information We Collect</h2>
          <ul className="list-disc pl-6 text-gray-700">
            <li className="mb-2">Personal Information: Name, email address, phone number, shipping address, and billing address.</li>
            <li className="mb-2">Payment Information: Payment details such as credit/debit card information or other payment method information. We use secure third-party payment processors and do not store your payment information directly.</li>
            <li className="mb-2">Order History: Details of purchases you have made with us.</li>
            <li className="mb-2">Technical Information: IP address, browser type, device information, and browsing history on our site, collected through cookies and similar technologies.</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">2. How We Use Your Information</h2>
          <ul className="list-disc pl-6 text-gray-700">
            <li className="mb-2">To process and fulfill your orders.</li>
            <li className="mb-2">To communicate with you regarding your order status, shipping updates, and customer support.</li>
            <li className="mb-2">To send you marketing and promotional emails if you’ve opted into receiving them.</li>
            <li className="mb-2">To improve our website, products, and services.</li>
            <li className="mb-2">To comply with legal requirements and protect our rights.</li>
          </ul>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">3. Sharing of Information</h2>
          <p className="text-gray-700 mb-4">
            We may share your information with:
          </p>
          <ul className="list-disc pl-6 text-gray-700">
            <li className="mb-2">Service Providers: Third-party providers who help us operate our business, such as payment processors, shipping companies, and email marketing platforms.</li>
            <li className="mb-2">Legal Compliance: If required by law or to protect our rights, we may disclose your information to authorities.</li>
          </ul>
          <p className="text-gray-700 mb-4">
            We do not sell, rent, or trade your personal information with third parties.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">4. Cookies and Tracking Technologies</h2>
          <p className="text-gray-700 mb-4">
            We use cookies and similar tracking technologies to enhance your browsing experience, analyze site traffic, and personalize content. You can choose to disable cookies through your browser settings, but this may impact your experience on our website.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">5. Data Security</h2>
          <p className="text-gray-700 mb-4">
            We take reasonable measures to protect your data, including encryption, secure servers, and limited access to your personal information. However, no data transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.
          </p>
        </section>

        <section className="mt-6">
          <h2 className="text-2xl font-semibold text-gray-900 mb-2">6. Changes to This Privacy Policy</h2>
          <p className="text-gray-700 mb-4">
            We may update this Privacy Policy from time to time. Any changes will be posted on this page, and we encourage you to review it periodically.
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
