// src/components/QRCodePayment.js
import React , { useState,useEffect ,useRef} from 'react';
import QRCode from 'react-qr-code';

const QRCodePayment = ({ amount,handleQRFormSubmit }) => {
  const noteRef = useRef(null);
  const [copied, setCopied] = useState(false);
  const [qrTrue,SetQrTrue] =useState(false);
  const [selectedPayment, setSelectedPayment] = useState('');
  // const vpa=encodeURIComponent('8939990949@ybl');
  // const name=encodeURIComponent('karthick');
  const calculateShipping = (basePrice) => {
    
    if (basePrice < 2500) {
      if(selectedPayment=='cod'){
        return 110;
      }
     else if(selectedPayment=='upi'){
        return 70;
      }
     else{
      return 0;
     }
    }else
    
    return 0;
  };
  const shipping = calculateShipping(amount);
   const vpaencod=encodeURIComponent('7358668512@ybl'); //8939990949@ybl
   const vpa='7358668512@ybl';
  const name='Nanitha';

  const amounts=(parseFloat(amount)+parseFloat(shipping));
  const currency="INR";
  const value=90
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
  const paymentLink = `upi://pay?pa=${vpa}&pn=${name}&am=${amounts}&cu=${currency}`;
  const googlePayUrl = `https://pay.google.com/gp/p/ui/pay?pa=${vpa}&pn=${name}&am=${amounts}&cu=INR`;
  const phonePeUrl   = `https://phon.pe/upi/pay?pa=${vpa}&pn=${name}&am=${amounts}&cu=INR`;
   // Handle redirect
   const handleUpiPayment = () => {
   // window.location.href =isIOS?paymentLink: paymentLink; // This will open the UPI app if installed

    if (isIOS) {
      // iOS Fallback with window.open
      window.open(paymentLink, '_blank');
    } else {
      // Direct redirection for non-iOS devices
      window.location.href = paymentLink;
    }
  };
  const [isGenerated, setIsGenerated] = useState(false);
  const [formDataQR, setFormDataQR] = useState({
    transactionid: '',    
  });
  const handleInputChangeQR = (e) => {
    const { name, value } = e.target;
    setFormDataQR({
      ...formDataQR,
      [name]: value,
    });
  };

  const handlePaymentSelect = (paymentMethod) => {
    setSelectedPayment(paymentMethod);
    if(paymentMethod=="upi"){
      if (noteRef.current) {
       // noteRef.current.scrollIntoView({ behavior: "smooth" });
        noteRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
        noteRef.current.focus(); // Optional: Add focus for accessibility
      }
    }
  };
  // Function to handle button click
  const handleGenerateQR = () => {
    setIsGenerated(true); // This will show the QR code
    handleUpiPayment();
  };
// Function to copy the UPI link to the clipboard
const copyToClipboard = () => {
  navigator.clipboard.writeText('7358668512').then(() => {
    setCopied(true);
    // Reset the copied state after 2 seconds
    setTimeout(() => setCopied(false), 2000);
  }).catch((err) => {
    console.error('Failed to copy: ', err);
  });
};
  const handleSubmitQR = (e) => {
    debugger;
    e.preventDefault();
    if (formDataQR.transactionid){
      handleQRFormSubmit(formDataQR.transactionid,selectedPayment,(parseFloat(amount)+parseFloat(shipping)))
    }
  };
  const handleSubmitcod = (e) => {
    debugger;
    e.preventDefault();   
      handleQRFormSubmit(selectedPayment,'',(parseFloat(amount)+parseFloat(shipping)))    
  };
  
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []); // Empty dependency array ensures it runs only on mount
  return (
   
<>
<div className="flex flex-col items-centerold justify-center h-screenold p-2 bg-gray-50old">

<div className="bg-whiteold bg-gray-50 shadow-lg rounded-lg p-4">
  {/* Title */}
 
  <div className=" ">
<h2 className="font-medium  text-gray-900 pb-2">Checkout Summary</h2>
</div>

    {/* Summary */}
    <div className="border-t border-gray-300 pt-2 ">
          <div className="text-sm flex justify-between text-gray-600 mb-2">
            <span>Subtotal:</span>
            <span className="font-mediumk text-gray-900">₹{amount}</span>
          </div>
          <div className="text-sm flex justify-between text-gray-600 mb-2">
            <span>Shipping:</span>
            <span className="text-gray-900">
              ₹{amount <2500  ? shipping : "Free"}
            </span>
          </div>
          {/* <div className="flex justify-between text-gray-600 mb-4">
            <span>Taxes (10%):</span>
            <span className="font-medium">₹{amount}</span>
          </div> */}
        
        </div>
        <div className="border-t border-gray-300 pt-4">
        <h2 className="text-md font-medium font-semibold text-gray-700 text-center mb-2">
    {/* SCAN QR CODE AND PAY */}Total Amount : {amounts}
  </h2>
  </div>

  {qrTrue && (
  // QR Code Container: SCAN QR CODE AND PAY
  <div className="flex flex-col items-center justify-center mb-4">
    {/* QR Code Placeholder (Initial State) */}
    {!isGenerated && !isIOS && (
      <div className="w-48 h-48 bg-gray-200 flex items-center justify-center rounded-md">
        <p className="text-gray-500">QR Code will appear here</p>
      </div>
    )}

    {/* QR Code Image (After clicking the button) */}
    {isGenerated && (
      <QRCode value={paymentLink} size={150} />
    )}
  </div>
)}

{/* Button to generate the QR code */}
{!isGenerated && !isIOS && qrTrue &&(
  <button
    onClick={handleGenerateQR}
    className="w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded-lg transition-all"
  >
    GENERATE QR CODE & PAY
  </button>
)}

{/* Instruction Text */}
{isGenerated && (
  <p className="mt-2 text-sm text-center text-gray-600">
    Tap or Scan and pay with any UPI App.
  </p>
)}

      <div className="max-w-md mx-auto p-2 bg-white shadow-md rounded-md">
      <h2 className="text-lg font-medium font-semiboldold mb-2">Select Payment Method</h2>
      <div className="space-y-4">
        {/* Cash on Delivery Option */}
        <div
          className={`p-4 border rounded-md cursor-pointer ${
            selectedPayment === 'cod' ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
          }`}
          onClick={() => handlePaymentSelect('cod')}
        >
          <h3 className="text-lg font-medium">Cash on Delivery</h3>
          <p className="text-sm text-gray-600">Pay with cash when your order is delivered.</p>
        </div>

        {/* UPI Payment Option */}
        <div
          className={`p-4 border rounded-md cursor-pointer ${
            selectedPayment === 'upi' ? 'border-blue-500 bg-blue-50' : 'border-gray-300'
          }`}
          onClick={() => handlePaymentSelect('upi')}
        >
          <h3 className="text-lg font-medium">UPI Payment</h3>
          <p className="text-sm text-gray-600">Pay instantly using your UPI ID.</p>
        </div>
      </div>
      {selectedPayment === 'upi' && (
        <div>
<button
        onClick={copyToClipboard}
        className="px-4 py-2 mt-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
      >
        {copied ? 'Link Copied!' : 'Copy UPI Mobile No And Pay in UPI App'}
      </button>

      <div  ref={noteRef} // Attach the ref to this section
        tabIndex={-1} // Makes it focusable
         className="max-w-lg mx-auto mt-8 p-6 bg-gray-100 border border-gray-300 rounded-lg shadow-md">
  <h2 className="text-lg font-semibold text-gray-800 mb-4">Note for Payment</h2>
  <div className="space-y-4 text-gray-700">
    <div className="flex items-start">
      <span className="mr-3 text-gray-500 font-bold">1.</span>
      <p className="leading-relaxed">
        For <span className="font-semibold text-gray-800">UPI Payment</span>: Copy the provided UPI link.
      </p>
    </div>
    <div className="flex items-start">
      <span className="mr-3 text-gray-500 font-bold">2.</span>
      <p className="leading-relaxed">
        Complete the payment using any <span className="font-semibold text-gray-800">UPI app</span>.
      </p>
    </div>
    <div className="flex items-start">
      <span className="mr-3 text-gray-500 font-bold">3.</span>
      <p className="leading-relaxed">
        After Payment: Copy the <span className="font-semibold text-gray-800">transaction ID</span> from your UPI app.
      </p>
    </div>
    <div className="flex items-start">
      <span className="mr-3 text-gray-500 font-bold">4.</span>
      <p className="leading-relaxed">
        Paste the transaction ID to finalize your payment and <span className="font-semibold text-gray-800">place the order</span>.
      </p>
    </div>
  </div>
</div>

    </div>
    )} 
      {/* Conditional Rendering for Payment Details */}
      {/* {selectedPayment === 'upi' && (
        <div className="mt-4">
          <label htmlFor="upiId" className="block text-sm font-medium text-gray-700">
            Enter UPI ID
          </label>
          <input
            type="text"
            id="upiId"
            className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:ring-blue-500 focus:border-blue-500"
            placeholder="example@upi"
          />
          <button className="mt-3 w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600">
            Pay Now
          </button>
        </div>
      )} */}
    </div>
</div>

</div>
 
 {/* Submit Button */}
 {selectedPayment === 'upi'  && (
  <form onSubmit={handleSubmitQR} className="space-y-6">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-2">
  <div >
  <label className="block mb-2">UPI Transaction ID</label>
            <input
              type="text"
              name="transactionid"
              placeholder="Transaction ID"
              required
              value={formDataQR.transactionid}
              onChange={handleInputChangeQR}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-indigo-500"
            />
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-600 text-black py-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:bg-blue-600 hover:text-indigo-600"
          >
            Continue to Place Order
          </button>
        </div>
 </form>)}

 { selectedPayment === 'cod' && (
  <form onSubmit={handleSubmitcod} className="space-y-6">
  <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-2">
          <button
            type="submit"
            className="w-full bg-indigo-600 text-black py-2 rounded-md  focus:outline-none focus:ring-2 focus:ring-indigo-500 hover:bg-blue-600 hover:text-indigo-600"
          >
            Continue to Place Order
          </button>
        </div>
 </form>)}
  </>
   );
};

export default QRCodePayment;
