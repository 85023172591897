import React from 'react';

const OverLappingImage = () => {
  return (
    <div className="relative grid grid-cols-1 md:grid-cols-3 gap-4 p-8">
      <div className="relative overflow-hidden rounded-lg shadow-lg">
        <img
          src="https://tailwindui.com/img/ecommerce-images/home-page-04-collection-01.jpg"
          alt="Woman wearing an off-white cotton t-shirt."
          className="w-full h-full object-cover transform transition-transform duration-500 hover:scale-105"
        />
      </div>
      <div className="relative overflow-hidden rounded-lg shadow-lg -mt-12 md:mt-0 md:-ml-8 z-10">
        <img
          src="https://tailwindui.com/img/ecommerce-images/home-page-04-collection-02.jpg"
          alt="Man wearing a charcoal gray cotton t-shirt."
          className="w-full h-full object-cover transform transition-transform duration-500 hover:scale-105"
        />
      </div>
      <div className="relative overflow-hidden rounded-lg shadow-lg -mt-12 md:mt-0 md:-ml-8 z-20">
        <img
          src="https://tailwindui.com/img/ecommerce-images/home-page-04-collection-03.jpg"
          alt="Person sitting at a wooden desk with paper note organizer, pencil and tablet."
          className="w-full h-full object-cover transform transition-transform duration-500 hover:scale-105"
        />
      </div>
    </div>
  );
};

export default OverLappingImage;
