import React from 'react';
import ImageSlider from './ImageSlider';

const ImageSliderShow = () => {
  const images = [
    'https://via.placeholder.com/800x400/0000FF/808080?text=Slide+1',
    'https://via.placeholder.com/800x400/FF0000/FFFFFF?text=Slide+2',
    'https://via.placeholder.com/800x400/00FF00/000000?text=Slide+3',
    'https://via.placeholder.com/800x400/FFFF00/000000?text=Slide+4',
  ];

  return (
    <div className="App">
      <ImageSlider images={images} interval={5000}/>
    </div>
  );
};

export default ImageSliderShow;
