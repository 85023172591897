import React from 'react';
import HeroSection from '../components/HeroSection/HeroSection';
import PerkSection from '../components/PerkSection/PerkSection';
import TrendingProducts from '../components/TrendingProducts/TrendingProducts';
import Categories from '../components/Categories/Categories';
const Home = () => {

  return (
    <><HeroSection />
    <Categories></Categories>
    <TrendingProducts />
    <PerkSection /></> 
  );
};

export default Home;
