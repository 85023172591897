import React from 'react';
import { CheckIcon, TruckIcon, ArrowPathIcon, SquaresPlusIcon,LifebuoyIcon,PhoneIcon,ChatBubbleLeftRightIcon    } from '@heroicons/react/24/outline';

const perks = [
  {
    name: 'Free Shipping on Orders Over Rs.2500',
    description: 'Enjoy fast, free delivery on all orders over Rs.2500. No hidden fees - Just beautiful jewelry delivered to your door!',
    icon: TruckIcon,
  },
  // {
  //   name: '30-day returns',
  //   description: 'Not satisfied? Return your order within 30 days.',
  //   icon: ArrowPathIcon,
  // },
  {
    name: '24/7 customer support',
    description: 'Have a question? Our friendly customer service team is available 24/7 to assist you with anything you need, from product inquiries to sizing help.',
    icon: PhoneIcon  ,
  },
  // {
  //   name: 'Secure payments',
  //   description: 'We ensure secure transactions for your peace of mind.',
  //   icon: CheckIcon,
  // },
];

const PerkSection = () => {
  return (
    <div className="bg-gray-50 py-16">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="lg:text-center">
          <h2 className="text-base text-blue-600 font-semibold tracking-wide uppercase">Our Perks</h2>
          <p className="mt-2 text-3xl font-extrabold tracking-tight text-slate-900">
            We provide the best shopping experience
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
            Discover the benefits of shopping with us. We offer excellent perks to make your shopping experience smooth and enjoyable.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            {perks.map((perk) => (
              <div key={perk.name} className="relative">
                <dt>
                  <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-blue-600 text-white">
                    <perk.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="ml-16 text-lg leading-6 font-medium text-gray-900">{perk.name}</p>
                </dt>
                <dd className="mt-2 ml-16 text-base text-gray-500">{perk.description}</dd>
              </div>
            ))}
          </dl>
        </div>
      </div>
    </div>
  );
};

export default PerkSection;
