import React from "react";

const OrderTracking = () => {
  return (
    <div className="min-h-screen bg-gray-50 py-8 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto bg-white p-6 rounded-lg shadow-lg">
        <h1 className="text-3xl font-bold text-gray-900 mb-6">Order Tracking Policy</h1>

        <p className="text-gray-700 mb-6">
          At Aarang Jewels, we strive to ensure that your shopping experience is seamless and transparent. This Order Tracking Policy will guide you through the process of tracking your orders and understanding what to do in case of delays or issues.
        </p>

        <section className="space-y-8">
          {/* Introduction Section */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">1. Introduction</h2>
            <p className="text-gray-700">
              Once your order is processed and shipped, you will be able to track the status of your shipment. This policy is designed to provide you with all necessary details regarding the tracking process.
            </p>
          </div>

          {/* How to Track Orders Section */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">2. How to Track Your Order</h2>
            <p className="text-gray-700 mb-4">
              You can track your order by entering the Order ID or Tracking Number provided in the confirmation email. Here's how:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Locate your Order ID or Tracking Number in your order confirmation email.</li>
              <li>Visit our "Order Tracking" page and enter the provided number.</li>
              <li>Click the "Track Order" button to view your current order status.</li>
            </ul>
          </div>

          {/* Order Status Information Section */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">3. Order Status Information</h2>
            <p className="text-gray-700">
              When tracking your order, you may see one of the following statuses:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li><strong>Order Confirmed:</strong> Your order has been confirmed, and we are preparing it for shipment.</li>
              <li><strong>Shipped:</strong> Your order has been dispatched and is on its way to the delivery address.</li>
              <li><strong>Out for Delivery:</strong> Your package is with the courier and will be delivered soon.</li>
              <li><strong>Delivered:</strong> The package has been successfully delivered to the provided address.</li>
              <li><strong>Pending:</strong> There is an issue with your order, and it is awaiting further processing.</li>
              <li><strong>Delayed:</strong> Your order has been delayed. We apologize for the inconvenience and are working to resolve the issue.</li>
            </ul>
          </div>

          {/* Delayed or Lost Package Section */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">4. Delayed or Lost Packages</h2>
            <p className="text-gray-700">
              If your order is delayed or lost, please take the following steps:
            </p>
            <ul className="list-disc pl-6 space-y-2 text-gray-700">
              <li>Check the tracking status for any updates or changes.</li>
              <li>If the status indicates a delay, please wait for 24-48 hours as couriers sometimes provide delayed updates.</li>
              <li>If there is no change after 48 hours, contact our customer support team for assistance.</li>
              <li>In the rare event that your package is lost, we will initiate an investigation with the courier and offer a resolution, which may include a replacement or refund.</li>
            </ul>
          </div>

          {/* Contact Support Section */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">5. Contacting Customer Support</h2>
            <p className="text-gray-700">
              If you need assistance with tracking your order or have concerns regarding delays, please reach out to our customer support team:
            </p>
            <div className="space-y-2">
              <p className="text-gray-700">
                <strong>Email:</strong> <a href="mailto:Aarangjewels@gmail.com" className="text-blue-500">Aarangjewels@gmail.com</a>
              </p>
              <p className="text-gray-700">
                <strong>Phone:</strong> +91-123-456-7890
              </p>
              <p className="text-gray-700">
                <strong>Business Hours:</strong> Monday - Friday, 9 AM - 6 PM IST
              </p>
            </div>
          </div>

          {/* Disclaimer Section */}
          <div>
            <h2 className="text-2xl font-semibold text-gray-900 mb-2">6. Disclaimer</h2>
            <p className="text-gray-700">
              Please note that once the package leaves our warehouse, the delivery process is in the hands of the shipping courier. We are not responsible for delays caused by the courier or external factors such as weather, customs clearance, etc.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default OrderTracking;
