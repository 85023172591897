import React from 'react';
// import bannerImage from '../assets/banner/Aarang - Banner.png';


const HeroSection = () => {
  const backgroundImage = `/assets/banner/Aarang - Banner.png`;
  
  return (
    <div
      // className="relative w-full h-screen bg-cover bg-center"
      className="relative w-full h-[300px] md:h-[600px] bg-banner-mobile md:bg-banner-desktop  bg-cover bg-center"
      
      // style={{ backgroundImage: `url('/assets/banner/Aarang - Banner.png')` }}
    >
      <div className="absolute inset-0 bg-black bg-opacity-50 flex flex-col justify-center items-center text-center p-4">
        <h1 className="text-white text-4xl md:text-6xl font-bold mb-4">
          Discover Our Latest Collections
        </h1>
        <p className="text-white text-lg md:text-2xl mb-6">
          Ethinic, Artisanal, and Experimental
        </p>
        {/* <button className="bg-yellow-500 text-black text-lg md:text-xl font-semibold py-3 px-6 rounded-lg shadow-lg hover:bg-yellow-600 transition duration-300">
          Shop Now
        </button> */}
      </div>
    </div>
  );
};

export default HeroSection;
